<template>
  <div class="px-4">
    <div class="justify-content-between d-flex f-14">
      <p>เลขที่สัญญา :</p>
      <p class="font-weight-bold">{{ profile.contractNo }}</p>
    </div>
    <div v-if="contract.collectionStatusUnits == 'C'" class="h-100">
      <hr />
      <diV class="text-center f-orange f-14 pt-4">
        สัญญาของท่านได้ปิดสัญญาเรียบร้อยแล้ว
      </diV>
    </div>
    <div
      v-else-if="
        contract.collectionStatusUnits == 'L' ||
        contract.collectionStatusUnits == 'SP'
      "
      class="h-100"
    >
      <hr />
      <diV class="text-center f-orange f-14 pt-4">
        จำนวนที่ท่านต้องชำระขึ้นอยู่กับจำนวนเงินตามที่ระบุในสัญญาสินเชื่อของท่านหรือเป็นไปตามที่ท่านได้ตกลงกับเจ้าหน้าที่ไว้
      </diV>
    </div>
    <div
      v-else-if="
        contract.contractType == 'HP' ||
        contract.contractType == 'FL' ||
        contract.contractType == 'PL' ||
        contract.contractType == 'TL'
      "
    >
      <b-row class="mt-1 0">
        <b-col cols="12" class="p-0">
          <div class="card-custom d-flex justify-content-between px-1">
            <div class="">
              <p class="f-14">
                ค่างวดคงเหลือทั้งสัญญา <span class="f-12">(บาท)</span>
              </p>
              <p class="f-12 f-gray">
                กำหนดชำระงวดสุดท้าย {{ contractInfo.EndDueDate }}
              </p>
            </div>
            <p class="font-weight-bold f-14 align-items-center d-flex">
              {{ contractInfo.TotalOutstandingBalance }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" class="mt-1 p-0">
          <div class="card-custom d-flex justify-content-between px-1">
            <div class="">
              <p class="f-14 text-nowrap">ค่างวดค้างชำระ <span class="f-12">(บาท)</span></p>
              <p class="f-12 f-gray">
                ค้างชำระตั้งแต่ {{ contractInfo.EndDueDate }} จำนวน
                {{ contractInfo.OverduePeriod }} งวด
              </p>
            </div>
            <p class="font-weight-bold f-14 align-items-center d-flex">
              {{ contractInfo.OverdueAmount }}
            </p>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="p-0"
          v-if="
            contract.contractType === 'HP' || contract.contractType === 'FL'
          "
        >
          <div class="d-flex justify-content-between mt-1 px-1 card-custom">
            <div class="">
              <p class="f-14 text-nowrap">ค่างวดถัดไป <span class="f-12">(บาท)</span></p>
              <p class="f-12 f-gray">
                กำหนดชำระในวันที่ {{ contractInfo.NextPaymentDueDate }}
              </p>
            </div>
            <p class="font-weight-bold f-14 align-items-center d-flex">
              {{ contractInfo.NextPaymentAmount }}
            </p>
          </div>
        </b-col>
        <b-col cols="6" class="mt-1 p-0">
          <div class="card-custom p-1 mr-1">
            <div class="">
              <p class="f-14 text-nowrap">ค่าเบี้ยปรับ <span class="f-12">(บาท)</span></p>
            </div>
            <p class="font-weight-bold f-14 f-orange text-end ">
              {{ contractInfo.PenaltyAmt || "0.00" }}
            </p>
          </div>
        </b-col>
        <b-col cols="6" class="mt-1 p-0">
          <div class="card-custom p-1">
            <div class="">
              <p class="f-14 text-nowrap">
                ค่าติดตามทวงถาม <span class="f-12">(บาท)</span>
              </p>
            </div>
            <p class="font-weight-bold f-14 f-orange text-end ">
              {{ contractInfo.CallCharge || "0.00" }}
            </p>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="p-0"
          v-if="
            contract.contractType === 'PL' || contract.contractType === 'TL'
          "
        >
          <div
            class="d-flex justify-content-between mt-1 px-1 py-2 card-custom bg-light-green"
          >
            <div class="">
              <p class="f-14 font-weight-bold ">
                รวมเงินที่ต้องชำระทั้งสิ้น <span class="">(บาท)</span>
              </p>
            </div>
            <p class="font-weight-bold f-14 align-items-center d-flex">
              {{ contractInfo.OverdueAmount }}
            </p>
          </div>
        </b-col>
        <b-col
          cols="6"
          class="mt-1 p-0"
          v-if="
            contract.contractType === 'HP' || contract.contractType === 'FL'
          "
        >
          <div class="card-custom p-1 mr-1">
            <div class="">
              <p class="f-14 text-nowrap">
                ค่าต่อภาษีประจำปี <span class="f-12">(บาท)</span>
              </p>
            </div>
            <p class="font-weight-bold f-14 f-orange text-end ">
              {{ contractInfo.TaxPaymentAmount || "0.00" }}
            </p>
          </div>
        </b-col>
        <b-col
          cols="6"
          class="mt-1 p-0"
          v-if="
            contract.contractType === 'HP' || contract.contractType === 'FL'
          "
        >
          <div class="card-custom p-1">
            <div class="">
              <p class="f-14 text-nowrap">
                ค่าต่อพ.ร.บ.ประจำปี <span class="f-12">(บาท)</span>
              </p>
            </div>
            <p class="font-weight-bold f-14 f-orange text-end ">
              {{ contractInfo.RIPaymentAmount || "0.00" }}
            </p>
          </div>
        </b-col>
      </b-row>
      <p class="f-12 text-center mt-2">
        *ยอดชำระค่างวดจะอัปเดตหลังจากวันชำระเงิน 2 วันทำการ
      </p>
      <p class="f-orange text-end f-12 mt-2">ข้อมูล ณ วันที่ {{ toDay }}</p>
    </div>
    <!-- <p class="mb-0 txt-note">
      ข้อมูล ณ วันที่ {{ contractInfo.CalculationDate || "-" }} <br />
    </p>
    <div class="display-inline w-100">
      <span class="txt-note"
        >หมายเหตุ : ข้อมูลที่แสดงในตารางเป็นข้อมูลค่างวดย้อนหลัง 3 เดือน</span
      >
    </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    profile: {
      required: false,
      type: Object
    },
    lineProfile: {
      required: false,
      type: Object
    },
    contract: {
      required: false,
      type: Object
    }
  },
  data: () => {
    return {
      callCenter: "",
      contractInfo: {},
      toDay: ""
    };
  },
  created() {
    this.getContratctInstallmentDetail();

    this.toDay = this.formatToThaiDate(new Date());
  },
  methods: {
    formatToThaiDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")}/${year}`;
    },
    async getContratctInstallmentDetail() {
      this.$isLoadingAlpha = true;
      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          ContractNo: this.contract.contractNo
        }),
        Path: `${this.$API_DEV_SKL}/contract_installment`,
        Method: "POST"
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.contractInfo = result.data;
          }
        })
        .catch(error => {
          console.error(error);
        });
      this.$isLoadingAlpha = false;
    }
  }
};
</script>

<style scoped>
.card-custom {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.txt-note {
  font-size: 12px;
}
.display-inline {
  display: inline-flex;
}

.bg-light-green {
  background-color: #b7e28b;
}


</style>

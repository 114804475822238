import { render, staticRenderFns } from "./QRcode.vue?vue&type=template&id=4d76de48&scoped=true"
import script from "./QRcode.vue?vue&type=script&lang=js"
export * from "./QRcode.vue?vue&type=script&lang=js"
import style0 from "./QRcode.vue?vue&type=style&index=0&id=4d76de48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d76de48",
  null
  
)

export default component.exports
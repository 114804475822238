<template>
  <div class="px-4">
    <div v-for="(field, index) in displayFields" :key="index">
      <div class="justify-content-between d-flex f-14">
        <p>{{ field.label }} :</p>
        <p class="font-weight-bold">{{ field.value || "-" }}</p>
      </div>
      <hr v-if="index < displayFields.length - 1" class="m-1" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    profile: {
      required: false,
      type: Object
    },
    lineProfile: {
      required: false,
      type: Object
    },
    contract: {
      required: false,
      type: Object
    }
  },
  data: () => {
    return {
      callCenter: "",
      contractInfo: {},
      toDay: "",
      displayFields: []
    };
  },
  created() {
    if (this.contract) {
      this.getContractDetail(this.contract.contractNo);
    }
    this.toDay = this.formatToThaiDate(new Date());
  },
  methods: {
    formatToThaiDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")}/${year}`;
    },
    async getContractDetail(contractNo) {
      this.$isLoadingAlpha = true;
      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          ContractNo: contractNo
        }),
        Path: `${this.$API_DEV_SKL}/contract_data`,
        Method: "POST"
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.contractInfo = result.data;
            this.displayFields = [
              { label: "เลขที่สัญญา", value: this.profile.contractNo },
              { label: "ประเภทสัญญา", value: this.contractInfo.ContractType },
              { label: "รุ่นรถ", value: this.contractInfo.Model },
              { label: "เลขป้ายทะเบียน", value: this.contractInfo.LicenceNo },
              {
                label: "วันที่จดทะเบียน",
                value: this.contractInfo.BookingDate
              },
              { label: "วันที่เริ่มสัญญา", value: this.contractInfo.AsOfDate },
              {
                label: "กำหนดชำระทุกวันที่",
                value: this.contractInfo.CompulsoryRenewalDate
              },
              {
                label: "สถานสัญญา",
                value: this.contractInfo.CollectionStatusUnits
              }
            ];
          }
        })
        .catch(error => {
          console.error(error);
        });
      this.$isLoadingAlpha = false;
    }
  }
};
</script>

<style scoped>
.card-custom {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.txt-note {
  font-size: 12px;
}
.display-inline {
  display: inline-flex;
}
</style>

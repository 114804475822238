<template>
  <div>
    <p>สัญญาสินเชื่อของท่าน</p>
    <div class="px-4">
      <div class="justify-content-between d-flex f-14">
        <p>เลขที่สัญญา :</p>
        <p class="font-weight-bold">{{ profile.contractNo }}</p>
      </div>
      <div class="px-4 f-14">
        <p class="font-weight-bold">ประเภทค่าใช้จ่าย :</p>
        <InputSelect
          :options="paymentTypeList"
          v-model="selectedPayment"
          @onDataChange="val => (selectedPayment = val)"
        ></InputSelect>
      </div>

      <div class="justify-content-between d-flex f-14 font-weight-bold">
        <p class="">ยอดเงินที่ต้องชำระ :</p>
        <p>{{ contractInfo.TotalAmount }}</p>
      </div>
      <div class="barcode-container px-4 f-12 text-center mt-4">
        <p class="font-weight-bold">บาร์โค้ด/QR Code ชำระเงินของท่าน</p>
        <p>รหัสอ้างอิง 1/ Ref.1: {{ numberRef }}</p>
        <div>
          <img
            :src="barcodeUrl.BarcodeBankURL"
            alt="barcode"
            class="barcode-size mt-2"
          />
        </div>
        <p class="f-10">{{ barcodeUrl.BarcodeBankText }}</p>
        <div>
          <img
            :src="barcodeUrl.QRCodeBankURL"
            alt="qr-code"
            class="barcode-size mt-2"
          />
        </div>
        <p class="f-10">{{ barcodeUrl.QRCodeBankText }}</p>
        <div class="mt-3">
          <a href="#" class="f-orange text-link"
            >คลิกที่รูปบาร์โค้ดคิวอาร์โค้ดเพื่อขยาย</a
          >
        </div>
      </div>
    </div>
    <hr />
    <div class="barcode-container px-4 f-14 text-center mt-4">
      <p class="font-weight-bold">วิธีการชำระเงินด้วยบาร์โค้ด/คิวอาร์โคด</p>
      <p>สแกนบาร์โค้ด/คิวอาร์โค้ดเพื่อชำระเงิน</p>
      <p>ตามช่องทางการชำระเงินด้านล่าง</p>

      <b-card class="bg-gray mt-2">
        <p>ชำระผ่านผู้ให้บริการ</p>
        <div class="bank-img-ciontainer d-flex justify-content-center mt-2">
          <img
            v-for="(option, index) in options"
            :key="index"
            :src="option.imageUrl"
            class="icon-bank px-2"
          />
        </div>

        <p class="mt-2">ช่องทางการชำระอิ่นๆ</p>
        <div class="bank-img-ciontainer d-flex justify-content-center mt-2">
          <img
            v-for="(option, index) in otherOptions"
            :key="index"
            :src="require(`@/assets/paymentImg/${option.src}`)"
            class="icon-bank px-2"
          />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import InputSelect from "../../../components/inputs/InputSelect.vue";

export default {
  components: { InputSelect },
  props: {
    profile: {
      required: false,
      type: Object
    },
    lineProfile: {
      required: false,
      type: Object
    },
    contract: {
      required: false,
      type: Object
    }
  },
  data: () => {
    return {
      lineId: "",

      callCenter: "",

      paymentTypeList: [
        { text: "ค่างวด", value: 0 },
        { text: "ค่าติดตามทวงถาม", value: 1 },
        { text: "ค่าเบี้ยปรับ", value: 2 },
        { text: "ค่าภาษี พรบ ประจำปี", value: 3 }
      ],
      selectedPayment: 0,
      paymentAmount: 0,
      state: "form",
      payPageHTML: "",
      pay_onlineResponse: {},
      numberRef: "",
      contractInfo: {},
      barcodeUrl: {},
      options: [],
      otherOptions: [
        { src: "counter.png", alt: "" },
        { src: "lotus.png", alt: "" },
        { src: "thaipost.png", alt: "" },
        { src: "mpay.png", alt: "" }
      ]
    };
  },
  created() {
    // this.getContactBarcode();
    // this.getContratctInstallment();
    if (
      this.contract.contractType == "PL" ||
      this.contract.contractType == "TL"
    ) {
      this.paymentTypeList = [{ text: "ทุกประเภทค่าใช้จ่าย", value: "all" }];
      this.selectedPayment = "all";
    }
  },
  methods: {
    getContratctInstallment() {
      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          ContractNo: this.contract.contractNo
        }),
        Path: `${this.$API_DEV_SKL}/contract_installment`,
        Method: "POST"
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.contractInfo = result.data;
          }
        })
        .catch(error => {
          console.error(error);
        });

      var myHeadersBankList = new Headers();
      myHeadersBankList.append("API-KEY", "S1112LIN05!");

      var requestOptionsBankList = {
        method: "GET",
        headers: myHeadersBankList,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/Bank/OnlinePayment`,
        requestOptionsBankList
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.options = result.detail;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getContactBarcode() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          ContractNo: this.contract.contractNo
        }),
        Path: `${this.$API_DEV_SKL}/contract_barcode`,
        Method: "POST"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.numberRef = result.data.BankBarcodeRef1;
            this.barcodeUrl = result.url;
            this.changeNumberRef(result.data.BankBarcodeRef1);
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    changeNumberRef(numberRef) {
      let indexContractNo = numberRef.indexOf(this.contract.contractNo);
      let lengthContractNo = this.contract.contractNo.length;
      this.numberRef = numberRef.substring(
        0,
        indexContractNo + lengthContractNo
      );
    }
  }
};
</script>

<style scoped>
.barcode-size {
  width: 100px;
  height: 100px;
}

.f-10 {
  font-size: 10px !important;
}

.bg-gray {
  background-color: rgb(231, 231, 231);
}

.icon-bank {
  height: 40px;
  border-radius: 50%;
}
</style>

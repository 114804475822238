var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4"},[_c('div',{staticClass:"justify-content-between d-flex f-14"},[_c('p',[_vm._v("เลขที่สัญญา :")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.profile.contractNo))])]),(_vm.contract.collectionStatusUnits == 'C')?_c('div',{staticClass:"h-100"},[_c('hr'),_c('diV',{staticClass:"text-center f-orange f-14 pt-4"},[_vm._v(" สัญญาของท่านได้ปิดสัญญาเรียบร้อยแล้ว ")])],1):(
      _vm.contract.collectionStatusUnits == 'L' ||
      _vm.contract.collectionStatusUnits == 'SP'
    )?_c('div',{staticClass:"h-100"},[_c('hr'),_c('diV',{staticClass:"text-center f-orange f-14 pt-4"},[_vm._v(" จำนวนที่ท่านต้องชำระขึ้นอยู่กับจำนวนเงินตามที่ระบุในสัญญาสินเชื่อของท่านหรือเป็นไปตามที่ท่านได้ตกลงกับเจ้าหน้าที่ไว้ ")])],1):(
      _vm.contract.contractType == 'HP' ||
      _vm.contract.contractType == 'FL' ||
      _vm.contract.contractType == 'PL' ||
      _vm.contract.contractType == 'TL'
    )?_c('div',[_c('b-row',{staticClass:"mt-1 0"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"card-custom d-flex justify-content-between px-1"},[_c('div',{},[_c('p',{staticClass:"f-14"},[_vm._v(" ค่างวดคงเหลือทั้งสัญญา "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])]),_c('p',{staticClass:"f-12 f-gray"},[_vm._v(" กำหนดชำระงวดสุดท้าย "+_vm._s(_vm.contractInfo.EndDueDate)+" ")])]),_c('p',{staticClass:"font-weight-bold f-14 align-items-center d-flex"},[_vm._v(" "+_vm._s(_vm.contractInfo.TotalOutstandingBalance)+" ")])])]),_c('b-col',{staticClass:"mt-1 p-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"card-custom d-flex justify-content-between px-1"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v("ค่างวดค้างชำระ "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])]),_c('p',{staticClass:"f-12 f-gray"},[_vm._v(" ค้างชำระตั้งแต่ "+_vm._s(_vm.contractInfo.EndDueDate)+" จำนวน "+_vm._s(_vm.contractInfo.OverduePeriod)+" งวด ")])]),_c('p',{staticClass:"font-weight-bold f-14 align-items-center d-flex"},[_vm._v(" "+_vm._s(_vm.contractInfo.OverdueAmount)+" ")])])]),(
          _vm.contract.contractType === 'HP' || _vm.contract.contractType === 'FL'
        )?_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mt-1 px-1 card-custom"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v("ค่างวดถัดไป "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])]),_c('p',{staticClass:"f-12 f-gray"},[_vm._v(" กำหนดชำระในวันที่ "+_vm._s(_vm.contractInfo.NextPaymentDueDate)+" ")])]),_c('p',{staticClass:"font-weight-bold f-14 align-items-center d-flex"},[_vm._v(" "+_vm._s(_vm.contractInfo.NextPaymentAmount)+" ")])])]):_vm._e(),_c('b-col',{staticClass:"mt-1 p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"card-custom p-1 mr-1"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v("ค่าเบี้ยปรับ "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])])]),_c('p',{staticClass:"font-weight-bold f-14 f-orange text-end"},[_vm._v(" "+_vm._s(_vm.contractInfo.PenaltyAmt || "0.00")+" ")])])]),_c('b-col',{staticClass:"mt-1 p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"card-custom p-1"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v(" ค่าติดตามทวงถาม "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])])]),_c('p',{staticClass:"font-weight-bold f-14 f-orange text-end"},[_vm._v(" "+_vm._s(_vm.contractInfo.CallCharge || "0.00")+" ")])])]),(
          _vm.contract.contractType === 'PL' || _vm.contract.contractType === 'TL'
        )?_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mt-1 px-1 py-2 card-custom bg-light-green"},[_c('div',{},[_c('p',{staticClass:"f-14 font-weight-bold"},[_vm._v(" รวมเงินที่ต้องชำระทั้งสิ้น "),_c('span',{},[_vm._v("(บาท)")])])]),_c('p',{staticClass:"font-weight-bold f-14 align-items-center d-flex"},[_vm._v(" "+_vm._s(_vm.contractInfo.OverdueAmount)+" ")])])]):_vm._e(),(
          _vm.contract.contractType === 'HP' || _vm.contract.contractType === 'FL'
        )?_c('b-col',{staticClass:"mt-1 p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"card-custom p-1 mr-1"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v(" ค่าต่อภาษีประจำปี "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])])]),_c('p',{staticClass:"font-weight-bold f-14 f-orange text-end"},[_vm._v(" "+_vm._s(_vm.contractInfo.TaxPaymentAmount || "0.00")+" ")])])]):_vm._e(),(
          _vm.contract.contractType === 'HP' || _vm.contract.contractType === 'FL'
        )?_c('b-col',{staticClass:"mt-1 p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"card-custom p-1"},[_c('div',{},[_c('p',{staticClass:"f-14 text-nowrap"},[_vm._v(" ค่าต่อพ.ร.บ.ประจำปี "),_c('span',{staticClass:"f-12"},[_vm._v("(บาท)")])])]),_c('p',{staticClass:"font-weight-bold f-14 f-orange text-end"},[_vm._v(" "+_vm._s(_vm.contractInfo.RIPaymentAmount || "0.00")+" ")])])]):_vm._e()],1),_c('p',{staticClass:"f-12 text-center mt-2"},[_vm._v(" *ยอดชำระค่างวดจะอัปเดตหลังจากวันชำระเงิน 2 วันทำการ ")]),_c('p',{staticClass:"f-orange text-end f-12 mt-2"},[_vm._v("ข้อมูล ณ วันที่ "+_vm._s(_vm.toDay))])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }